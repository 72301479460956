<template>
  <div class="app-container">
    <div class="filter-container" style="display: flex;flex-wrap: wrap;">
      <el-select filterable v-model="listQuery.shop_id" placeholder="请选择店铺" class="filter-item" style="width: 220px" clearable>
        <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id"></el-option>
      </el-select>

      <el-date-picker v-model="dateArr" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="filter-item" style="display: flex;" clearable></el-date-picker>

      <el-button type="primary" class="filter-item" @click="handleFilter">搜索</el-button>

      <download-excel :fields="json_fields" :fetch="handleExport" type="xls" name="店铺财务列表.xls" worksheet="店铺财务列表">
        <el-button type="success" :loading="exportLoading">导出</el-button>
      </download-excel>
    </div>

    <div v-loading="listLoading">
      <el-row :gutter="20">
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.orderPayMoney || 0 }}</div>
            <div class="subtitle">线上订单总额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.orderPayCount || 0 }}</div>
            <div class="subtitle">线上订单总数</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.orderLedgerMoney || 0 }}</div>
            <div class="subtitle">线上订单分账额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.orderReceiverPayMoney || 0 }}</div>
            <div class="subtitle">配送单总额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.orderReceiverPayCount || 0 }}</div>
            <div class="subtitle">配送单总数</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.orderReceiverLedgerMoney || 0 }}</div>
            <div class="subtitle">配送单分账额</div>
          </el-card>
        </el-col>
        <el-col :sm="4" v-for="(item,index) in statistics.orderReceiverLedgerLog" :key="index">
          <el-card class="box-card">
            <div class="value" style="color: red;">{{ item.ledger_role_money }}</div>
            <div class="subtitle">{{ item.role_name }}</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.orderSelfTakePayMoney || 0 }}</div>
            <div class="subtitle">打包带走订单总额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.orderSelfTakePayCount || 0 }}</div>
            <div class="subtitle">打包带走订单总数</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.orderSelfTakeLedgerMoney || 0 }}</div>
            <div class="subtitle">打包带走订单分账额</div>
          </el-card>
        </el-col>
        <el-col :sm="4" v-for="(item,index) in statistics.orderSelfTakeLedgerLog" :key="index">
          <el-card class="box-card">
            <div class="value" style="color: red;">{{ item.ledger_role_money }}</div>
            <div class="subtitle">{{ item.role_name }}</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.orderDineInPayMoney || 0 }}</div>
            <div class="subtitle">扫码下单堂食总额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.orderDineInPayCount || 0 }}</div>
            <div class="subtitle">扫码下单堂食总数</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.orderDineInLedgerMoney || 0 }}</div>
            <div class="subtitle">扫码下单堂食分账额</div>
          </el-card>
        </el-col>
        <el-col :sm="4" v-for="(item,index) in statistics.orderDineInLedgerLog" :key="index">
          <el-card class="box-card">
            <div class="value" style="color: red;">{{ item.ledger_role_money }}</div>
            <div class="subtitle">{{ item.role_name }}</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.cashierOrderWeChatPayMoney || 0 }}</div>
            <div class="subtitle">收银微信总额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.cashierOrderWeChatPayCount || 0 }}</div>
            <div class="subtitle">收银微信总数</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.cashierOrderWeChatLedgerMoney || 0 }}</div>
            <div class="subtitle">收银微信分账额</div>
          </el-card>
        </el-col>
        <el-col :sm="4" v-for="(item,index) in statistics.cashierOrderWeChatLedgerLog" :key="index">
          <el-card class="box-card">
            <div class="value" style="color: red;">{{ item.ledger_role_money }}</div>
            <div class="subtitle">{{ item.role_name }}</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.cashierOrderAlipayPayMoney || 0 }}</div>
            <div class="subtitle">收银支付宝总额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.cashierOrderAlipayPayCount || 0 }}</div>
            <div class="subtitle">收银支付宝总数</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.cashierOrderAlipayLedgerMoney || 0 }}</div>
            <div class="subtitle">收银支付宝分账额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.cashierOrderFuiouWeChatPayMoney || 0 }}</div>
            <div class="subtitle">收银富友微信总额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.cashierOrderFuiouWeChatPayCount || 0 }}</div>
            <div class="subtitle">收银富友微信总数</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.cashierOrderFuiouWeChatLedgerMoney || 0 }}</div>
            <div class="subtitle">收银富友微信分账额</div>
          </el-card>
        </el-col>
        <el-col :sm="4" v-for="(item,index) in statistics.cashierOrderFuiouWeChatLedgerLog" :key="index">
          <el-card class="box-card">
            <div class="value" style="color: red;">{{ item.ledger_role_money }}</div>
            <div class="subtitle">{{ item.role_name }}</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.cateredStudentOrderPayMoney || 0 }}</div>
            <div class="subtitle">学生包餐总额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.cateredStudentOrderPayCount || 0 }}</div>
            <div class="subtitle">学生包餐总数</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.teacherCardOrderPayMoney || 0 }}</div>
            <div class="subtitle">教师卡总额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.teacherCardOrderPayCount || 0 }}</div>
            <div class="subtitle">教师卡总数</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.scanCodeOrderWeChatPayMoney || 0 }}</div>
            <div class="subtitle">扫码支付微信总额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.scanCodeOrderWeChatPayCount || 0 }}</div>
            <div class="subtitle">扫码支付微信总数</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.scanCodeOrderWeChatLedgerMoney || 0 }}</div>
            <div class="subtitle">扫码支付微信分账额</div>
          </el-card>
        </el-col>
        <el-col :sm="4" v-for="(item,index) in statistics.scanCodeOrderWeChatLedgerLog" :key="index">
          <el-card class="box-card">
            <div class="value" style="color: red;">{{ item.ledger_role_money }}</div>
            <div class="subtitle">{{ item.role_name }}</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.terminalOrderFaceScanPayMoney || 0 }}</div>
            <div class="subtitle">终端刷脸总额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.terminalOrderFaceScanPayCount || 0 }}</div>
            <div class="subtitle">终端刷脸总数</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ statistics.terminalOrderFaceScanLedgerMoney || 0 }}</div>
            <div class="subtitle">终端刷脸分账额</div>
          </el-card>
        </el-col>
        <el-col :sm="4" v-for="(item,index) in statistics.terminalOrderFaceScanLedgerLog" :key="index">
          <el-card class="box-card">
            <div class="value" style="color: red;">{{ item.ledger_role_money }}</div>
            <div class="subtitle">{{ item.role_name }}</div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column label="店铺ID" width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>

      <el-table-column label="店铺名称" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.shop_name }}</div>
        </template>
      </el-table-column>

      <el-table-column label="店铺封面" min-width="110" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.thumb" class="user-avatar">
        </template>
      </el-table-column>

      <el-table-column label="线上订单总额" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.orderPayMoney }}</div>
        </template>
      </el-table-column>

      <el-table-column label="线上订单总数" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.orderPayCount }}</div>
        </template>
      </el-table-column>

      <el-table-column label="线上订单分账额" min-width="120" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.orderLedgerMoney }}</div>
        </template>
      </el-table-column>

      <el-table-column label="配送单总额" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.orderReceiverPayMoney }}</div>
        </template>
      </el-table-column>

      <el-table-column label="配送单总数" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.orderReceiverPayCount }}</div>
        </template>
      </el-table-column>

      <el-table-column label="配送单分账额" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.orderReceiverLedgerMoney }}</div>
        </template>
      </el-table-column>

      <el-table-column label="打包带走订单总额" min-width="140" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.orderSelfTakePayMoney }}</div>
        </template>
      </el-table-column>

      <el-table-column label="打包带走订单总数" min-width="140" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.orderSelfTakePayCount }}</div>
        </template>
      </el-table-column>

      <el-table-column label="打包带走订单分账额" min-width="150" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.orderSelfTakeLedgerMoney }}</div>
        </template>
      </el-table-column>

      <el-table-column label="扫码下单堂食总额" min-width="140" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.orderDineInPayMoney }}</div>
        </template>
      </el-table-column>

      <el-table-column label="扫码下单堂食总数" min-width="140" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.orderDineInPayCount }}</div>
        </template>
      </el-table-column>

      <el-table-column label="扫码下单堂食分账额" min-width="150" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.orderDineInLedgerMoney }}</div>
        </template>
      </el-table-column>

      <el-table-column label="收银微信总额" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.cashierOrderWeChatPayMoney }}</div>
        </template>
      </el-table-column>

      <el-table-column label="收银微信总数" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.cashierOrderWeChatPayCount }}</div>
        </template>
      </el-table-column>

      <el-table-column label="收银微信分账额" min-width="120" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.cashierOrderWeChatLedgerMoney }}</div>
        </template>
      </el-table-column>

      <el-table-column label="收银支付宝总额" min-width="120" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.cashierOrderAlipayPayMoney }}</div>
        </template>
      </el-table-column>

      <el-table-column label="收银支付宝总数" min-width="120" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.cashierOrderAlipayPayCount }}</div>
        </template>
      </el-table-column>

      <el-table-column label="收银支付宝分账额" min-width="140" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.cashierOrderAlipayLedgerMoney }}</div>
        </template>
      </el-table-column>

      <el-table-column label="收银富友微信总额" min-width="140" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.cashierOrderFuiouWeChatPayMoney }}</div>
        </template>
      </el-table-column>

      <el-table-column label="收银富友微信总数" min-width="140" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.cashierOrderFuiouWeChatPayCount }}</div>
        </template>
      </el-table-column>

      <el-table-column label="收银富友微信分账额" min-width="150" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.cashierOrderFuiouWeChatLedgerMoney }}</div>
        </template>
      </el-table-column>

      <el-table-column label="学生包餐总额" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.cateredStudentOrderPayMoney }}</div>
        </template>
      </el-table-column>

      <el-table-column label="学生包餐总数" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.cateredStudentOrderPayCount }}</div>
        </template>
      </el-table-column>

      <el-table-column label="教师卡总额" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.teacherCardOrderPayMoney }}</div>
        </template>
      </el-table-column>

      <el-table-column label="教师卡总数" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.teacherCardOrderPayCount }}</div>
        </template>
      </el-table-column>

      <el-table-column label="扫码支付微信总额" min-width="140" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.scanCodeOrderWeChatPayMoney }}</div>
        </template>
      </el-table-column>

      <el-table-column label="扫码支付微信总数" min-width="140" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.scanCodeOrderWeChatPayCount }}</div>
        </template>
      </el-table-column>

      <el-table-column label="扫码支付微信分账额" min-width="150" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.scanCodeOrderWeChatLedgerMoney }}</div>
        </template>
      </el-table-column>

      <el-table-column label="终端刷脸总额" min-width="140" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.terminalOrderFaceScanPayMoney }}</div>
        </template>
      </el-table-column>

      <el-table-column label="终端刷脸总数" min-width="140" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.terminalOrderFaceScanPayCount }}</div>
        </template>
      </el-table-column>

      <el-table-column label="终端刷脸分账额" min-width="150" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.terminalOrderFaceScanLedgerMoney }}</div>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="150" align="center" fixed="right" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button type="success" size="mini" @click="handleCashierDeviceOrder(scope.row)">查看扫码机明细</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5, 10, 20, 30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
  import request from "@/utils/request";
  import { mapGetters } from "vuex";
  import downloadExcel from "vue-json-excel";
  import moment from "moment";

  export default {
    components: {
      downloadExcel
    },
    data() {
      return {
        list: null,
        total: null,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          school_id: "",
          shop_id: "",
          start_date: "",
          end_date: "",
        },
        shops: [],
        dateArr: null,
        json_fields: {
          店铺ID: "id",
          店铺名称: "shop_name",
          线上订单总额: "orderPayMoney",
          线上订单总数: "orderPayCount",
          线上订单分账额: "orderLedgerMoney",
          配送单总额: "orderReceiverPayMoney",
          配送单总数: "orderReceiverPayCount",
          配送单分账额: "orderReceiverLedgerMoney",
          打包带走订单总额: "orderSelfTakePayMoney",
          打包带走订单总数: "orderSelfTakePayCount",
          打包带走订单分账额: "orderSelfTakeLedgerMoney",
          扫码下单堂食总额: "orderDineInPayMoney",
          扫码下单堂食总数: "orderDineInPayCount",
          扫码下单堂食分账额: "orderDineInLedgerMoney",
          收银微信总额: "cashierOrderWeChatPayMoney",
          收银微信总数: "cashierOrderWeChatPayCount",
          收银微信分账额: "cashierOrderWeChatLedgerMoney",
          收银支付宝总额: "cashierOrderAlipayPayMoney",
          收银支付宝总数: "cashierOrderAlipayPayCount",
          收银支付宝分账额: "cashierOrderAlipayLedgerMoney",
          收银富友微信总额: "cashierOrderFuiouWeChatPayMoney",
          收银富友微信总数: "cashierOrderFuiouWeChatPayCount",
          收银富友微信分账额: "cashierOrderFuiouWeChatLedgerMoney",
          学生包餐总额: "cateredStudentOrderPayMoney",
          学生包餐总数: "cateredStudentOrderPayCount",
          教师卡总额: "teacherCardOrderPayMoney",
          教师卡总数: "teacherCardOrderPayCount",
          扫码支付微信总额: "scanCodeOrderWeChatPayMoney",
          扫码支付微信总数: "scanCodeOrderWeChatPayCount",
          扫码支付微信分账额: "scanCodeOrderWeChatLedgerMoney",
          终端刷脸总额: "terminalOrderFaceScanPayMoney",
          终端刷脸总数: "terminalOrderFaceScanPayCount",
          终端刷脸分账额: "terminalOrderFaceScanLedgerMoney",
        },
        exportLoading: false,
        statistics: {
          orderPayMoney: 0,
          orderPayCount: 0,
          orderLedgerMoney: 0,
          orderReceiverPayMoney: 0,
          orderReceiverPayCount: 0,
          orderReceiverLedgerMoney: 0,
          orderReceiverLedgerLog: [],
          orderSelfTakePayMoney: 0,
          orderSelfTakePayCount: 0,
          orderSelfTakeLedgerMoney: 0,
          orderSelfTakeLedgerLog: [],
          orderDineInPayMoney: 0,
          orderDineInPayCount: 0,
          orderDineInLedgerMoney: 0,
          orderDineInLedgerLog: [],
          cashierOrderWeChatPayMoney: 0,
          cashierOrderWeChatPayCount: 0,
          cashierOrderWeChatLedgerMoney: 0,
          cashierOrderWeChatLedgerLog: [],
          cashierOrderAlipayPayMoney: 0,
          cashierOrderAlipayPayCount: 0,
          cashierOrderAlipayLedgerMoney: 0,
          cashierOrderFuiouWeChatPayMoney: 0,
          cashierOrderFuiouWeChatPayCount: 0,
          cashierOrderFuiouWeChatLedgerMoney: 0,
          cashierOrderFuiouWeChatLedgerLog: [],
          cateredStudentOrderPayMoney: 0,
          cateredStudentOrderPayCount: 0,
          teacherCardOrderPayMoney: 0,
          teacherCardOrderPayCount: 0,
          scanCodeOrderWeChatPayMoney: 0,
          scanCodeOrderWeChatPayCount: 0,
          scanCodeOrderWeChatLedgerMoney: 0,
          scanCodeOrderWeChatLedgerLog: [],
          terminalOrderFaceScanPayMoney: 0,
          terminalOrderFaceScanPayCount: 0,
          terminalOrderFaceScanLedgerMoney: 0,
          terminalOrderFaceScanLedgerLog: [],
        }
      };
    },
    created() {
      this.listQuery.start_date = moment().format("YYYY-MM-DD");
      this.listQuery.end_date = moment().format("YYYY-MM-DD");
      this.dateArr = [this.listQuery.start_date, this.listQuery.end_date];
      this.getList();
      this.getShopList();
    },
    computed: {
      ...mapGetters(["schools", "school_id", "user"])
    },
    watch: {
      school_id(newVal, oldVal) {
        this.listQuery.school_id = newVal;
        this.getList();
        this.getShopList();
      },
      dateArr(newVal, oldVal) {
        if (newVal) {
          this.listQuery.start_date = newVal[0];
          this.listQuery.end_date = newVal[1];
        } else {
          this.listQuery.start_date = "";
          this.listQuery.end_date = "";
        }
      }
    },
    methods: {
      getList() {
        this.listQuery.school_id = this.school_id;
        this.listLoading = true;
        request({
          url: "/api/backend/shop/financeList",
          method: "get",
          params: this.listQuery,
          timeout: 6000000,
        }).then((response) => {
          this.list = response.data.list.data;
          this.total = response.data.list.total;
          this.statistics = response.data.statistics;
          this.listLoading = false;
        });
      },
      getShopList() {
        request({
          url: "/api/backend/shop/list",
          method: "get",
          params: {
            page: 1,
            limit: 1000000,
            school_id: this.school_id,
          }
        }).then(response => {
          this.shops = response.data.data;
        });
      },
      handleFilter() {
        this.listQuery.page = 1;
        this.getList();
      },
      handleSizeChange(val) {
        this.listQuery.limit = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.listQuery.page = val;
        this.getList();
      },
      handleExport() {
        if (!this.listQuery.start_date || !this.listQuery.end_date) {
          this.$message({
            type: "warning",
            message: "请选择时间段"
          });
          return;
        }

        let listQuery = JSON.parse(JSON.stringify(this.listQuery));
        listQuery.page = 1;
        listQuery.limit = this.total;
        this.exportLoading = true;
        return request({
          url: "/api/backend/shop/financeList",
          method: "get",
          params: listQuery,
          timeout: 6000000,
        }).then(response => {
          if (response.data.list.data.length <= 0) {
            this.$message({
              type: "warning",
              message: "暂无记录"
            });
            return;
          }
          return response.data.list.data;
        }).catch((err) => {
          console.log(err);
        }).finally(() => {
          this.exportLoading = false;
        });
      },
      // 查看扫码机明细
      handleCashierDeviceOrder(row) {
        this.$router.push(`/shop/cashierDeviceOrder?shop_id=${row.id}&start_date=${this.listQuery.start_date}&end_date=${this.listQuery.end_date}`);
      },
    },
  };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 6px;
  }
  .el-col{
    margin-bottom: 20px;
  }
  .box-card {
    text-align: center;
    .value {
      color: #546DFE;
      font-weight: bold;
      font-size: 26px;
    }
    .subtitle {
      font-size: 12px;
      color: gray;
      margin-top: 6px;
    }
  }
</style>
